var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form_wrap t2" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formattedSdt,
          expression: "formattedSdt",
        },
      ],
      ref: "dp",
      staticClass: "wid120 inp_cal",
      attrs: {
        type: "text",
        placeholder: _vm.$t("comp.012"),
        name: "sdt",
        maxlength: "10",
      },
      domProps: { value: _vm.formattedSdt },
      on: {
        change: _vm.changeDt,
        keydown: _vm.keyinput,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.formattedSdt = $event.target.value
        },
      },
    }),
    _c("div", { staticClass: "input_calendar inline" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formattedEdt,
            expression: "formattedEdt",
          },
        ],
        staticClass: "inp_cal",
        attrs: {
          type: "text",
          name: "edt",
          placeholder: _vm.$t("comp.013"),
          maxlength: "10",
        },
        domProps: { value: _vm.formattedEdt },
        on: {
          click: _vm.showRangePicker,
          change: _vm.changeDt,
          keydown: _vm.keyinput,
          input: function ($event) {
            if ($event.target.composing) return
            _vm.formattedEdt = $event.target.value
          },
        },
      }),
      _c(
        "button",
        {
          staticClass: "tbl_icon calendar",
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
            },
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.toggleRangePicker.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("comp.014")))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }